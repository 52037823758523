define('loftus/models/bill', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    scannableType: _emberData.default.attr('string'),
    scannableId: _emberData.default.attr('number'),
    documentType: _emberData.default.attr('string'),
    pages: _emberData.default.attr('number'),
    url: _emberData.default.attr('string'),
    md5: _emberData.default.attr('string'),
    fileSize: _emberData.default.attr('number'),
    scannedAt: _emberData.default.attr('date'),
    reviewedById: _emberData.default.attr('number'),
    reviewedAt: _emberData.default.attr('date'),
    completedById: _emberData.default.attr('number'),
    completedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    likelyModel: _emberData.default.attr('string'),
    invoiceDate: _emberData.default.attr('date'),
    dueDate: _emberData.default.attr('date'),
    reference: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    qbId: _emberData.default.attr('string'),
    qbSeq: _emberData.default.attr('string'),
    qbSyncedAt: _emberData.default.attr('date'),
    description: _emberData.default.attr('string'),
    bucket: _emberData.default.attr('string'),
    userActions: _emberData.default.hasMany('user-action'),
    vendor: _emberData.default.belongsTo('vendor'),
    user: _emberData.default.belongsTo('user'),
    customer: _emberData.default.belongsTo('customer'),
    print: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' })
  });
});